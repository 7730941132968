import React from 'react';

const Hello = () => {
  return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="100%" height="100%" viewBox="0 0 983.000000 387.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,387.000000) scale(0.100000,-0.100000)"
    fill="#fafafa" stroke="none">
    <path d="M6082 3815 c-208 -71 -374 -201 -613 -480 -150 -175 -429 -557 -561
    -770 -255 -410 -467 -816 -582 -1110 -42 -109 -52 -125 -105 -175 -91 -86
    -534 -329 -728 -400 -313 -114 -602 -111 -726 8 -27 26 -53 93 -44 116 3 7 38
    25 78 41 296 114 602 354 699 548 37 75 46 119 31 168 -27 89 -118 117 -260
    80 -97 -25 -169 -60 -269 -131 -159 -112 -259 -229 -337 -393 -30 -63 -61
    -103 -160 -210 -320 -345 -608 -542 -843 -578 -74 -11 -82 -11 -124 9 -43 21
    -99 84 -132 149 -25 51 -65 199 -87 323 -25 149 -36 186 -65 237 -29 51 -49
    63 -104 63 -84 0 -118 -28 -515 -427 -266 -267 -335 -328 -335 -294 0 8 163
    308 290 531 26 47 55 99 63 115 9 19 36 42 69 60 152 82 447 299 749 551 259
    217 412 358 679 630 216 219 327 351 460 546 232 339 293 613 159 719 -39 30
    -43 31 -115 27 -66 -4 -87 -12 -197 -66 -272 -136 -555 -410 -976 -946 -239
    -303 -351 -460 -539 -748 -192 -295 -271 -424 -389 -633 -49 -88 -127 -221
    -173 -295 -112 -182 -302 -556 -334 -658 l-26 -83 20 -39 c24 -48 55 -70 96
    -70 45 0 104 51 334 291 107 112 251 263 320 334 69 72 168 176 220 231 146
    155 162 150 200 -61 34 -195 57 -275 106 -372 79 -158 166 -223 299 -223 96 0
    172 21 290 80 182 92 368 250 581 496 64 74 120 134 124 134 4 0 4 -20 -1 -45
    -20 -106 81 -280 198 -343 171 -91 348 -80 691 44 188 68 373 162 548 280 86
    57 158 104 160 104 11 0 1 -51 -26 -145 -66 -226 -95 -427 -88 -607 6 -150 20
    -210 61 -279 93 -156 243 -158 503 -10 273 156 441 296 867 723 287 288 351
    347 363 337 20 -17 18 -36 -20 -168 -46 -156 -72 -296 -87 -456 -19 -213 16
    -392 94 -474 90 -94 217 -94 399 0 299 153 482 304 992 817 198 199 362 360
    364 358 1 -2 -20 -39 -48 -82 -62 -95 -120 -214 -155 -317 -41 -122 -31 -226
    26 -292 80 -91 206 -54 398 116 179 158 315 344 438 597 l74 153 28 -20 c72
    -52 263 -111 416 -130 204 -25 699 -9 919 29 91 16 98 19 94 43 -5 37 -59 41
    -199 18 -278 -48 -820 -54 -987 -12 -82 21 -226 84 -240 105 -4 6 -7 70 -7
    141 0 122 -2 133 -28 187 -48 98 -139 155 -202 126 -24 -11 -65 -72 -65 -98 0
    -6 -48 -64 -106 -128 -58 -64 -123 -138 -143 -164 -20 -26 -43 -50 -51 -53 -8
    -3 -152 -141 -320 -307 -552 -545 -739 -716 -961 -875 -216 -154 -365 -223
    -481 -223 -60 0 -105 37 -139 112 -20 46 -24 73 -27 196 -5 202 37 431 130
    704 l33 96 190 163 c444 380 629 552 955 891 629 653 944 1098 976 1376 10 92
    -25 158 -101 193 -57 26 -186 26 -267 -2 -266 -89 -493 -294 -841 -761 -175
    -236 -285 -397 -399 -588 -233 -389 -403 -711 -487 -920 -26 -64 -57 -132 -69
    -151 -29 -47 -618 -629 -812 -803 -179 -160 -402 -330 -525 -400 -124 -71
    -199 -98 -281 -104 -68 -4 -72 -3 -107 27 -21 19 -45 55 -59 89 -20 51 -22 74
    -22 222 -1 178 6 224 69 465 56 215 89 301 127 333 334 277 628 542 897 807
    450 444 788 833 991 1140 90 136 132 220 171 337 53 158 30 256 -71 308 -58
    30 -182 27 -278 -5z m230 -25 c162 -45 98 -307 -156 -643 -242 -321 -592 -698
    -976 -1050 -383 -351 -459 -420 -565 -508 -131 -111 -163 -124 -141 -61 23 61
    229 490 283 587 149 266 189 335 287 495 60 96 145 227 190 290 45 63 102 143
    125 178 127 189 356 439 508 555 91 69 198 124 288 146 95 23 108 24 157 11z
    m1705 -9 c77 -36 92 -114 47 -249 -69 -207 -271 -482 -669 -910 -238 -256
    -358 -373 -708 -691 -381 -346 -515 -456 -533 -438 -8 7 29 92 139 320 198
    412 454 848 690 1177 211 296 312 417 437 530 170 152 284 222 422 259 92 25
    123 25 175 2z m-5266 -100 c27 -28 29 -35 29 -108 0 -170 -89 -373 -265 -608
    -102 -136 -368 -436 -492 -556 -260 -251 -663 -582 -983 -810 -260 -185 -285
    -189 -196 -31 31 55 56 104 56 108 0 5 20 38 44 74 24 36 87 135 141 220 149
    235 309 473 389 579 40 53 95 130 123 172 50 76 338 428 480 586 92 102 280
    264 376 322 83 51 163 80 225 80 35 1 49 -5 73 -28z m5541 -1952 c8 -30 8 -48
    -1 -79 -11 -37 -13 -39 -37 -28 -27 13 -64 66 -64 92 0 10 14 31 30 48 36 36
    53 28 72 -33z m-4894 25 c68 -19 -24 -189 -172 -315 -94 -80 -267 -195 -390
    -259 -88 -46 -100 -50 -109 -35 -11 20 -5 45 35 149 27 68 43 93 130 187 117
    127 228 208 344 250 79 28 120 34 162 23z m4807 -143 c71 -89 71 -104 -1 -245
    -98 -194 -184 -315 -333 -469 -148 -153 -283 -244 -318 -215 -12 10 -14 26 -8
    88 6 67 15 90 84 225 64 126 104 187 248 376 140 185 249 309 269 309 2 0 28
    -31 59 -69z"/>
    </g>
    </svg>
  )
}

export default Hello;
